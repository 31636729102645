import { faCircle } from "@jewlr/pro-solid-svg-icons/faCircle"
import { faDiamond } from "@jewlr/sharp-solid-svg-icons/faDiamond"
import { faHeart } from "@jewlr/sharp-solid-svg-icons/faHeart"
import { faSquare } from "@jewlr/sharp-solid-svg-icons/faSquare"
import { Box, Flex, FontAwesomeIcon } from "@jewlr/storybook/core"
import PropTypes from "prop-types"
import React from "react"
import styled, { keyframes } from "styled-components"

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`

const Container = styled(Box).attrs((props) => ({
  py: 4,
  ...props,
}))`
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 5%,
    hsl(0 0% 0% / 1) 95%,
    hsl(0 0% 0% / 0)
  );
`

const Marquee = styled(Flex).attrs((props) => ({
  overflow: "hidden",
  position: "relative",
  userSelect: "none",
  ...props,
}))``

const MarqueeContent = styled(Flex).attrs((props) => ({
  alignItems: "baseline",
  color: "white",
  fontSize: "16px",
  fontWeight: "600",
  forwardedAs: "ul",
  justifyContent: "space-around",
  lineHeight: "1.2",
  m: 0,
  minWidth: "fit-content",
  p: 0,
  textTransform: "uppercase",
  ...props,
}))`
  animation: ${scroll} ${(props) => props.duration || 40}s linear infinite;
  flex-shrink: 0;
`

const MarqueeItem = styled(Box).attrs((props) => ({
  alignItems: "center",
  as: "li",
  display: "inline-flex",
  mx: 2,
  ...props,
}))``

const Confetti = styled(FontAwesomeIcon).attrs({
  fontSize: "10px",
})``

const confettiMap = {
  0: faDiamond,
  1: faSquare,
  2: faCircle,
  3: faHeart,
}

const LandingMarquee = ({ element }) => {
  const items = (hidden = false) =>
    (element.data?.items || []).map((item, i) => {
      return (
        <React.Fragment key={`marquee-${i}`}>
          <MarqueeItem {...(hidden && { "aria-hidden": true })} role="none">
            <Confetti icon={confettiMap[i % 4]} />
          </MarqueeItem>
          <MarqueeItem
            {...(hidden && { "aria-hidden": true })}
            {...element.style?.item_style}
            {...item.style}
          >
            {item.text}
          </MarqueeItem>
        </React.Fragment>
      )
    })

  return (
    <Box bg="blackPrint" color="white" {...element.style?.block_style}>
      <Container
        containWide={element.contain}
        {...element.style?.container_style}
      >
        <Marquee>
          <MarqueeContent
            duration={element.data?.duration}
            {...element.style?.marquee_style}
          >
            {items()}
            {items(true)}
          </MarqueeContent>
          <MarqueeContent
            aria-hidden
            duplicate
            duration={element.data?.duration}
            {...element.style?.marquee_style}
          >
            {items()}
            {items()}
          </MarqueeContent>
        </Marquee>
      </Container>
    </Box>
  )
}

LandingMarquee.propTypes = {
  element: PropTypes.object,
}

export default LandingMarquee
